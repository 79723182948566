<template>
  <div class="bg-light" style="padding-bottom: 100px">
    <Header></Header>
    <div class="container">
      <div class="titleArea">
        <h1 style="margin-bottom: 0">{{ blog.title }}&nbsp;
          <small>
            <b-icon slot="icon" icon="hand-thumbs-up"></b-icon>
            ({{ blog.favorite }})</small></h1>
      </div>
      <div style="display: block">
        <div class="tagArea" style="float: left">
          <el-tag v-for="(tag,o) in tags" :key="o" :type="colors[o%5-1]" class="tag">{{ tag }}</el-tag>
        </div>
        <div class="favoriteArea" style="float: right">
          <vue-star animate="animated bounceIn" color="#F05654">
            <b-icon slot="icon" :icon="heart" @click="favorite" font-scale="2"></b-icon>
            <small
              style="margin-left: 5px">({{ blog.favorite }})</small>
          </vue-star>

        </div>
      </div>
      <br>
      <el-divider></el-divider>
      <div class="contentArea">
        <el-row :gutter="20">
          <el-col :md="18" :sm="24">
            <el-card>
              <div class="content markdown-body bg-white" style="padding: 10px" v-html="blog.content"></div>
            </el-card>
            <el-divider></el-divider>
            <div class="commentArea">
              <el-card>
                <h2>博客评论：</h2>
                <AddComment @doReload="reload" :blogId="blogId"></AddComment>
                <div class="block" v-if="blogComments!=null" style="margin-top: 50px">
                  <el-timeline v-for="c in blogComments" :key="c.id" style="padding: 0!important;">
                    <el-timeline-item :timestamp="c.gmtCreate" placement="top">
                      <el-avatar style="float: left;margin: 5px 10px 5px 0"></el-avatar>
                      <div>
                        {{ c.student.name }}
                        <el-card>
                          {{ c.content }}
                        </el-card>
                      </div>
                    </el-timeline-item>
                  </el-timeline>
                </div>
                <div style="text-align: center">
                  <el-pagination
                    layout="prev, pager, next"
                    :total="totalCount"
                    :current-page.sync="currentPage"
                    :page-size="pageSize"
                    @current-change="showBlogCommentById"
                  >
                  </el-pagination>
                </div>
              </el-card>
            </div>
          </el-col>
          <el-col :md="6" :sm="24">
            <el-card id="sideBox">
              <el-avatar style="vertical-align: middle"></el-avatar>
              <span style="margin-left: 10px">{{ blog.student.name }}</span>
              <el-divider></el-divider>
              <div class="otherCourse">
                <p>相关Blog:
                  <el-link>查看更多</el-link>
                </p>
                <el-table
                  :show-header="false"
                  :data="relatedBlogs"
                  style="width: 100%">
                  <el-table-column
                    prop="name"
                    width="180">
                  </el-table-column>
                </el-table>
              </div>
              <el-button type="primary" style="width: 100%" @click="goBlogs">返回主页</el-button>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import axios from 'axios'
import 'github-markdown-css/github-markdown.css'
// eslint-disable-next-line
import MarkdownIt from 'markdown-it'
import AddComment from '@/components/AddComment'
import VueStar from 'vue-star'

export default {
  name: 'BlogDetails',
  components: {
    AddComment,
    Header,
    VueStar
  },
  data () {
    return {
      tags: [],
      totalCount: 0,
      currentPage: 1,
      pageSize: 5,
      heart: 'heart',
      colors: ['success', 'info', 'warning', 'danger', 'primary'],
      blogId: 0,
      blog: {},
      ownBlog: false,
      blogComments: [],
      relatedBlogs: [{ name: 'jdk的安装' }, { name: '封装的常见问题' }, { name: '冒泡排序' }, { name: 'jdk的安装' }, { name: '封装的常见问题' }, { name: '冒泡排序' }]
    }
  },
  mounted () {
    window.addEventListener('scroll', this.scrollToTop)
  },
  created () {
    this.blogId = this.$route.params.blogId
    this.getBlogById()
    this.showBlogCommentById()
    this.getBlogTags()
    this.hljs.initHighlightingOnLoad()
  },
  beforeRouteLeave (to, form, next) {
    window.removeEventListener('scroll', this.scrollToTop)
    next()
  },
  methods: {
    scrollToTop () {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      const sideBox = document.getElementById('sideBox')
      if (scrollTop >= 450) {
        sideBox.classList.add('fixedBox')
        this.sideButton = true
      } else {
        sideBox.classList.remove('fixedBox')
        this.sideButton = false
      }
    },
    favorite () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/favorite',
        params: {
          id: this.blogId
        }
      }).then(res => {
        if (res.data.status) {
          this.heart === 'heart' ? this.heart = 'heart-fill' : this.heart = 'heart'
          this.$message.success(res.data.message)
          this.blog.favorite += 1
        }
      })
    },
    goBlogs () {
      this.$router.push({ path: '/blogs' })
    },
    reload () {
      this.showBlogCommentById()
    },
    getBlogTags () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogTag/showTagsByBlogId',
        params: {
          blogId: this.blogId
        }
      }).then(res => {
        if (res.data.status) {
          this.tags = res.data.data
        }
      })
    },
    getBlogById () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blog/showBlogById',
        params: {
          id: this.blogId
        }
      }).then(res => {
        if (res.data.status) {
          this.blog = res.data.data
          const MarkdownIt = require('markdown-it')
          const md = new MarkdownIt()
          const result = md.render(this.blog.content)
          this.blog.content = result
        }
      })
    },
    showBlogCommentById () {
      axios({
        method: 'POST',
        url: 'https://mooc.kirinnorine.top/blogComment/showBlogCommentsByBlogIdDesc',
        params: {
          blogId: this.blogId,
          currentPage: this.currentPage,
          pageSize: this.pageSize
        }
      }).then(res => {
        if (res.data.status) {
          this.blogComments = res.data.data.content
          this.totalCount = res.data.data.totalCount
        }
      })
    },
    getAvatar (email) {
      let avatarUrl = ''
      avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + 565843270 + '&s=100'
      if (email.substring(email.length - 7) === '@qq.com') {
        const qqId = email.substring(0, email.indexOf('@'))
        avatarUrl = 'http://q1.qlogo.cn/g?b=qq&nk=' + qqId + '&s=100'
      }
      return avatarUrl
    }
  }
}
</script>

<style scoped>
.titleArea {
  margin: 50px 0 10px 0;
}

.contentArea {
  margin: 30px 0;
}

.tag {
  margin: 0 5px;
}

.favoriteArea {
}

.fixedBox {
  margin-top: 100px;
  top: 0;
  position: fixed;
  z-index: 100;
}

#sideBox {
  transition: 2s;
}
</style>
